<template>
    <section v-if="isVisible" v-show="isAvailable" class="main-page-announces">
        <div class="a-container">
            <div v-if="content.list" class="list">
                <div
                    v-for="(item, itemIndex) in content.list"
                    :key="`item-metric-${itemIndex}`"
                    class="item"
                >
                    <a-picture v-if="item.picture?.link" v-bind="item.picture" />
                    <div class="content">
                        <s-main-page-tag v-bind="tag({name: 'title'}, item)" />
                        <s-main-page-tag v-bind="tag({name: 'description'}, item)" />
                        <s-main-page-cta :content="item.cta" />
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
import APicture from '@core/components/picture/picture.vue';
import SMainPageCta from '../components/cta.vue';
import mixin from '../components/mixin.js';
import SMainPageTag from '../components/tag.vue';

export default {
    name: 'SMainPageAnnounces',
    components: {
        APicture,
        SMainPageCta,
        SMainPageTag,
    },
    mixins: [mixin],
    props: {
        content: {
            type: Object,
            default: null,
        },
    },
    computed: {
        isVisible() {
            return this.content?.list?.length;
        },
    },
};
</script>

<style lang="postcss" scoped>
.main-page-announces {
    padding-top: 48px;
    .list {
        gap: 16px;
        display: flex;
        flex-direction: column;
        @media (--viewport-desktop) {
            flex-direction: row;
        }
    }
    .item {
        display: flex;
        margin: 0 auto;
        overflow: hidden;
        border-radius: 8px;
        flex-direction: column;
        background: var(--av-inversed-primary);
        border: 1px solid var(--av-brand-secondary-light);
        box-shadow: 0 4px 8px 0 rgba(36, 49, 67, 0.1);
        @media (--viewport-tablet) {
            width: 428px;
        }
        @media (--viewport-desktop) {
            flex: 1 1 0;
            width: auto;
        }
    }
    &:deep(.a-picture) {
        border-bottom: 1px solid var(--av-brand-secondary-light);
        @media (--viewport-desktop) {
            min-height: 300px;
        }
        .a-picture__img {
            width: 100%;
            height: 100%;
        }
    }
    .content {
        height: 100%;
        display: flex;
        padding: 16px;
        text-align: center;
        flex-direction: column;
    }
    .title {
        @mixin lead-accent;
        color: var(--av-nav-primary);
        @media (--viewport-tablet) {
            @mixin title-accent;
        }
        @media (--viewport-desktop) {
            @mixin lead-accent;
        }
        @media (--viewport-desktop-wide) {
            @mixin title-accent;
        }
    }
    .description {
        @mixin paragraph;
        margin-top: 16px;
        color: var(--av-fixed-light);
    }
    .main-page-cta {
        margin-top: auto;
        padding-top: 16px;
        &:deep(.a-button) {
            width: 100%;
        }
    }
}
</style>
